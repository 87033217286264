import BSN from "bootstrap.native"
import Tabulator from "tabulator-tables";
import Choices from "choices.js";
import AutoNumeric from 'autonumeric';
import vNotify from "../vanilla-notify"
import { ApplicationController } from "../application_controller";

const TABLE_ID = "deal_air_tickets_form_table"

export default class extends ApplicationController {
  static targets = ["table",
    "main_form",
    "ticket_tr",
    "ticket_tr_currency",
    "modal_currency_field",
    "modal_currency_text",
    "modal_price_currency",
    "air_ticket_fields_template",
    "form_table_tbody",
    "form_table",
    "applications_link",
    "mapping_dropdown",
    "search",
    "status",
    "deal_period",
    "service_period",
    "supplier",
    "deal_user",
    "multiplicity",
    "visa_type",
    "train_price_select"
  ]

  connect() {
    // Main table
    if (this.hasTableTarget) {
      const content_url = this.data.get("table-load-url");
      this.createTable(content_url);
    }
    // FORM TABLE
    if (this.hasForm_tableTarget) this.setFormTotalHtml();
    if (this.hasTrain_price_selectTarget) this.initTrainPriceChoices();
  }

  filterTable() {
    this.setFilterToTable(false);
  }

  changeTicketTrCurrency(event) {
    const tr = event.currentTarget.closest("tr");
    const currency = event.currentTarget.getAttribute("value");
    const trCurrencyText = tr.querySelectorAll(".modal_currency_text");
    trCurrencyText.forEach(el => {
      el.innerHTML = currency;
    });
    this.setTrTotalHtml(tr);
    this.setFormTotalHtml();
  }

  priceChange(event) {
    const tr = event.currentTarget.closest("tr");
    this.setTrTotalHtml(tr);
    this.setFormTotalHtml();
  }

  countChange(event) {
    const tr = event.currentTarget.closest("tr");
    this.setTrTotalHtml(tr);
    this.setFormTotalHtml();
  }

  onRedemptionDateChange(event) {
    const tr = event.currentTarget.closest("tr");
    let redemption_date = event.currentTarget.value;
    let statemnt_status_choices = tr.querySelector(".status_select").choices;

    if (redemption_date) {
      statemnt_status_choices.setChoiceByValue("discharged");
    } else {
      statemnt_status_choices.setChoiceByValue("not_discharged");
    }
  }

  // private

  //
  // Form price choices
  //
  initTrainPriceChoices() {
    //
    // выбор билета, обновляем цену
    //
    let target = this.train_price_selectTarget
    let form_taget = target.closest('form')
    let this_controller = this.thisController(form_taget)

    let tr = target.closest("tr")
    let price_target = tr.querySelector(".price_input")

    const options = JSON.parse(target.dataset.options || null);
    const selected = JSON.parse(target.dataset.selected || null);
    let choice;

    if (target.choices) {
      choice = target.choices
    } else {
      choice = new Choices(target, {
        placeholder: false,
        removeItemButton: false,
        removeItems: false,
        searchEnabled: false,
        loadingText: this.messageText("loadingText"),
        noResultsText: this.messageText("noResultsText"),
        noChoicesText: this.messageText("noChoicesText"),
        itemSelectText: this.messageText("itemSelectText")
      })
      target.choices = choice;
    }
    if (options) choice.setChoices(options, "value", "label", true)
    if (selected) choice.setChoiceByValue(selected)
    // change
    choice.passedElement.element.addEventListener(
      "change",
      function (event) {
        let price = choice.getValue().customProperties.price;
        price_target.numeric.set(price)
        this_controller.setTrTotalHtml(tr)
        this_controller.setFormTotalHtml()
      },
      false,
    );
    this.addValidateTrigger(target, choice)
  }


  //
  // enable_disable buttons
  //
  enableDisableSaveButtons() {
    const save_and_new_btn = document.getElementById("foods_save_and_new")
    const save_btn = document.getElementById("foods_save")
    const empty_form_info = document.getElementById("foods_empty_form_info")
    const table = document.getElementById(TABLE_ID)
    const rows = table.tBodies[0].querySelectorAll("tr:not(.hidden)")
    if (rows.length > 0) {
      if (save_and_new_btn) save_and_new_btn.removeAttribute("disabled")
      save_btn.removeAttribute("disabled")
      empty_form_info.classList.add("hidden")
    } else {
      if (save_and_new_btn) save_and_new_btn.setAttribute("disabled", "disabled")
      save_btn.setAttribute("disabled", "disabled")
      empty_form_info.classList.remove("hidden")
    }
  }
  //
  // reindex table
  //
  reindexTableRows() {
    const table = document.getElementById(TABLE_ID)
    const rows = table.tBodies[0].querySelectorAll("tr:not(.hidden)")
    for (let i = 0; i < rows.length; i++) {
      let tr = rows[i];
      const index_target = tr.querySelector("span.index")
      index_target.innerHTML = i + 1
    }
  }

  //
  // set tr sum amount
  //
  setTrTotalHtml(tr) {
    const totals_hash = this.calculateTrSum(tr);
    const total_main_value_html = tr.querySelector(".total_main_value")
    total_main_value_html.innerHTML = super.setNumericFormat(totals_hash["main"]);

    const total_additional_value_html = tr.querySelector(".total_additional_value")
    total_additional_value_html.innerHTML = super.setNumericFormat(totals_hash["additional"]);
  }

  //
  // calculate tr sum amount
  //
  calculateTrSum(tr) {
    const main_additional_rate = tr.getAttribute("data-main-additional-rate").replace(",", '.').replace(/[^\d.-]/g, '');
    const service_currency_to_additional_rate = tr.getAttribute("data-service-currency-to-additional-rate").replace(",", '.').replace(/[^\d.-]/g, '');
    const service_currency_to_main_rate = tr.getAttribute("data-service-currency-to-main-rate").replace(",", '.').replace(/[^\d.-]/g, '');

    // const currency_target = tr.querySelector("ul.nav.nav-radio.nav-radio--group input[type='radio']:checked")
    const price_target = tr.querySelector("input.price_input")
    const count_target = tr.querySelector("input.count_input")

    let price = parseFloat(price_target.value.replace(",", '.').replace(/[^\d.-]/g, ''))
    let count = parseFloat(count_target.value.replace(",", '.').replace(/[^\d.-]/g, ''))
    // let to_main_rate = parseFloat(currency_target.getAttribute("data-rate-to-main").replace(",", '.').replace(/[^\d.-]/g, ''))

    // const to_additional_rate = parseFloat(currency_target.getAttribute("data-rate-to-additional").replace(",", '.').replace(/[^\d.-]/g, ''))
    price = price || 0
    count = count || 0
    // to_main_rate = to_main_rate || 0
    // to_additional_rate = to_additional_rate || 0

    let main_total = price * count * service_currency_to_main_rate
    let additional_total = price * count * service_currency_to_additional_rate

    return {
      "main": main_total,
      "additional": additional_total
    }
  }

  //
  // итого для формы
  //
  setFormTotalHtml() {
    const air_tickets_form_total_main_target = document.getElementById("air_tickets_form_total_main_value")
    const air_tickets_form_total_additional_target = document.getElementById("air_tickets_form_total_additional_value")
    const totals_hash = this.calculateFormTotal();

    air_tickets_form_total_main_target.innerHTML = super.setNumericFormat(totals_hash["main"]);
    air_tickets_form_total_additional_target.innerHTML = super.setNumericFormat(totals_hash["additional"]);
  }


  //
  // расчет итого по строкам таблицы
  //
  calculateFormTotal() {
    const table = document.getElementById(TABLE_ID)
    const rows = table.tBodies[0].rows
    let totals_hash = {
      "main": 0,
      "additional": 0
    }
    for (let i = 0; i < rows.length; i++) {
      let tr = rows[i];
      let row_totals_hash = this.calculateTrSum(tr);
      totals_hash["main"] += row_totals_hash["main"]
      totals_hash["additional"] += row_totals_hash["additional"]
    }
    return totals_hash
  }

  // update deal service tr and sub table
  onPostPutSuccess(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      // show notify
      window.vNotify.success({ text: data.notification_message, title: data.notification_title });

      // update row data
      const rowData = data.data
      const mainTable = document.getElementById("air_tickets_table")
      const mainTabulator = mainTable.tabulator
      const childTableId = "air_tickets_table_" + rowData.id;
      const row = mainTabulator.getRow(rowData.id)
      row.update(rowData)

      // show child table
      const rowCell = row.getCell("deal_services__air_tickets_links");
      // console.log(rowCell);
      this.toggleAirTicketsTable(rowCell.getElement())

      // hide form modal
      if (data.save_and_new === true || data.save_and_new === "true") {
        // modal set content
        window.layout_modal_xl.setContent(data.form_content)
      } else {
        window.layout_modal_xl.hide();
      }
    }
  }

  // update deal service form
  onPostPutError(event) {
    const [data, status, xhr] = event.detail;
    // modal set content
    window.layout_modal_xl.setContent(data.form_content)
  }

  toggleAirTickets(event) {
    this.toggleAirTicketsTable(event.currentTarget)
  }

  mappingOpen(event) {
    if (this.hasMapping_dropdownTarget) {
      const mapping_dropdown = this.mapping_dropdownTarget;
      new BSN.Dropdown(mapping_dropdown).toggle();
    }
  }

  mappingNotCloseClick(event) {
    event.stopPropagation();
  }

  toggleAirTicketsTable(div_target) {
    let tr = div_target.closest('.tabulator-row')
    let icon = div_target.querySelector('i')
    if (tr) tr.classList.toggle("show-child-table")
    if (icon) {
      if (icon.classList.contains("fa-angle-down")) {
        icon.classList.remove("fa-angle-down")
        icon.classList.add("fa-angle-up")
      } else {
        icon.classList.remove("fa-angle-up")
        icon.classList.add("fa-angle-down")
      }
    }
  }

  openModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalXL(url);
  }

  updateCurrencyFields(event) {
    const currency = event.currentTarget.getAttribute("value");
    this.modal_currency_fieldTargets.forEach(el => {
      el.innerHTML = currency;
    })
  }

  addNew(event) {
    event.preventDefault();
    const template_row = this.air_ticket_fields_templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.form_table_tbodyTarget.insertAdjacentHTML('beforeend', template_row)
    this.reindexFormTableRows()
  }

  removeFileds(event) {
    event.preventDefault();
    let response = confirm(event.currentTarget.getAttribute("data-confirm-message"));
    if (response) {
      const tr = event.currentTarget.closest("tr");
      let id = event.currentTarget.getAttribute("data-id")
      if (id) {
        if (id != "nil") {
          let destroy_target = document.getElementById(id)
          if (destroy_target) destroy_target.value = true
        }
      }
      tr.remove();
      this.reindexFormTableRows()
      this.setFormTotalHtml();
    }
  }

  //
  // private
  //
  //
  // Main table filters
  setFilterToTable(is_mapping) {
    let table = this.tableTarget.tabulator;
    let filters = []

    // let search_data = []
    if (this.hasSearchTarget) {
      filters.push({
        field: "q",
        type: "=",
        value: this.searchTarget.value
      })
    }

    if (this.hasDeal_periodTarget) {
      filters.push({
        field: "deal_period",
        type: "=",
        value: this.deal_periodTarget.value
      })
    }

    if (this.hasService_periodTarget) {
      filters.push({
        field: "service_period",
        type: "=",
        value: this.service_periodTarget.value
      })
    }

    if (this.hasStatusTarget) {
      filters.push({
        field: "status",
        type: "=",
        value: this.statusTarget.choices.getValue(true)
      })
    }

    if (this.hasDeal_userTarget) {
      filters.push({
        field: "user_ids",
        type: "=",
        value: this.deal_userTarget.choices.getValue(true)
      })
    }

    if (this.hasSupplierTarget) {
      filters.push({
        field: "supplier_ids",
        type: "=",
        value: this.supplierTarget.choices.getValue(true)
      })
    }

    if (this.hasMultiplicityTarget) {
      filters.push({
        field: "multiplicity_ids",
        type: "=",
        value: this.multiplicityTarget.choices.getValue(true)
      })
    }

    if (this.hasVisa_typeTarget) {
      filters.push({
        field: "visa_type_ids",
        type: "=",
        value: this.visa_typeTarget.choices.getValue(true)
      })
    }

    if (filters.length) {
      table.setFilter(filters);
    }
  }

  //
  // reindex table
  //
  reindexFormTableRows() {
    const table = this.form_tableTarget
    const rows = table.tBodies[0].querySelectorAll("tr:not(.hidden)")
    for (let i = 0; i < rows.length; i++) {
      let tr = rows[i];
      const index_target = tr.querySelector("span.index")
      index_target.innerHTML = i + 1
    }
  }

  // Create main table
  async createTable(url) {
    const columns = this.data.get("table-columns");
    const table_target = this.tableTarget;

    const default_props = super.tabulatorDefaultProps
    const height = document.getElementById('content').clientHeight;
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");

    let addAirTickets = this.addAirTicketsSubTable
    let air_tickets_columns = this.airTicketsColumns()
    air_tickets_columns = air_tickets_columns[super.appLocale]

    let table_props = {
      virtualDomBuffer: 4000,
      index: "id",
      height: (height - 180) + "px",
      persistenceID: "air_tickets_main_table",
      movableColumns: true,
      // columns
      columns: JSON.parse(columns),

      // sort
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],

      // pagination
      pagination: "remote",
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      paginationSizeSelector: [10, 25, 50],
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",
      //
      rowFormatter: function (row) {
        const data = row.getData();
        if (data.deal_services__status) {
          row.getElement().classList.add("row-" + data.deal_services__status)
        }

        if (data.deal_services__manager_status) {
          row.getElement().classList.add("row--" + data.deal_services__manager_status + "-bg")
        }
        //
        // add tickets sub table
        //
        addAirTickets(row, air_tickets_columns);
      }
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }

  addAirTicketsSubTable(row, air_tickets_columns) {
    const air_tickets_data = row.getData().deal_services__air_tickets
    const tableId = "air_tickets_table_" + row.getData().id;
    const childTable = document.getElementById(tableId)

    if (childTable) {
      const childTabulator = childTable.tabulator
      childTabulator.setData(air_tickets_data)
    } else {
      //create and style holder elements
      let holderEl = document.createElement("div");
      holderEl.classList = "child-table air-tickets-table ml-auto mr-20";
      holderEl.style.padding = "0 0 10px 20px";
      let tableEl = document.createElement("div");
      tableEl.classList = "table table-striped";
      tableEl.setAttribute("id", tableId);
      holderEl.appendChild(tableEl);

      row.getElement().appendChild(holderEl);

      if (air_tickets_data) {
        tableEl.tabulator = new Tabulator(tableEl, {
          minHeight: 300,
          layout: "fitDataTable",
          data: air_tickets_data,
          headerSort: true,
          columns: air_tickets_columns
        })
      }
    }
  }

  airTicketsColumns() {
    let sort_price = this.htmlPriceSorter
    const columns = {
      "ru": [{
        title: "№",
        field: "index",
        formatter: "plaintext",
        headerSort: false,
        width: 50
      },
      {
        title: "Дата брони",
        field: "booking_date",
        headerSort: true,
        width: 120
      },
      {
        title: "Дедлайн",
        field: "deadline",
        headerSort: true,
        width: 100
      },
      {
        title: "Класс",
        field: "price_name",
        headerSort: true,
        width: 100
      },
      {
        title: "Цена билета",
        field: "price",
        formatter: "html",
        headerSort: true,
        hozAlign: "right",
        sorter: "number",
        sorter: function (a, b, aRow, bRow, column, dir, sorterParams) {
          const parser = new DOMParser();
          const a_col_html = parser.parseFromString(a, "text/html");
          let a_usd_value = a_col_html.querySelector("div.font-bold").innerHTML.replace(/[^\d.-]/g, '');
          const b_col_html = parser.parseFromString(b, "text/html");
          let b_usd_value = b_col_html.querySelector("div.font-bold").innerHTML.replace(/[^\d.-]/g, '');
          return a_usd_value - b_usd_value;
        },
        width: 120
      },
      {
        title: "Кол-во",
        field: "count",
        headerSort: true,
        width: 80
      },
      {
        title: "Итого",
        field: "total",
        formatter: "html",
        hozAlign: "right",
        sorter: "number",
        sorter: function (a, b, aRow, bRow, column, dir, sorterParams) {
          const parser = new DOMParser();
          const a_col_html = parser.parseFromString(a, "text/html");
          let a_usd_value = a_col_html.querySelector("div.font-bold").innerHTML.replace(/[^\d.-]/g, '');
          const b_col_html = parser.parseFromString(b, "text/html");
          let b_usd_value = b_col_html.querySelector("div.font-bold").innerHTML.replace(/[^\d.-]/g, '');
          return a_usd_value - b_usd_value;
        },
        headerSort: true,
        width: 140
      },
      {
        title: "Дата выкупа",
        field: "redemption_date",
        headerSort: true,
        width: 120
      },
      {
        title: "Статус выписки",
        field: "status",
        headerSort: true,
        width: 150
      },
      {
        title: "Номер брони",
        field: "booking_number",
        headerSort: true,
        width: 120
      },
      {
        title: "Статус брони",
        field: "booking_status",
        headerSort: true,
        width: 120
      },
      {
        title: "Комментарий",
        field: "comment",
        headerSort: true,
        width: 200
      }],
      "en": [{
        title: "№",
        field: "index",
        formatter: "plaintext",
        headerSort: false,
        width: 50
      },
      {
        title: "Booking date",
        field: "booking_date",
        headerSort: true,
        width: 120
      },
      {
        title: "Deadline",
        field: "deadline",
        headerSort: true,
        width: 100
      },
      {
        title: "Class",
        field: "price_name",
        headerSort: true,
        width: 100
      },
      {
        title: "Ticket price",
        field: "price",
        formatter: "html",
        headerSort: true,
        hozAlign: "right",
        width: 120
      },
      {
        title: "Amount",
        field: "count",
        headerSort: true,
        width: 80
      },
      {
        title: "Total",
        field: "total",
        formatter: "html",
        hozAlign: "right",
        headerSort: true,
        width: 140
      },
      {
        title: "Redemption date",
        field: "redemption_date",
        headerSort: true,
        width: 120
      },
      {
        title: "Status",
        field: "status",
        headerSort: true,
        width: 150
      },
      {
        title: "Booking number",
        field: "booking_number",
        headerSort: true,
        width: 120
      },
      {
        title: "Booking status",
        field: "booking_status",
        headerSort: true,
        width: 120
      },
      {
        title: "Comment",
        field: "comment",
        headerSort: true,
        width: 200,
        formatter: 'column_wrap'
      }]
    }
    return columns
  }

  messageText(key) {
    let locale = document.body.getAttribute("data-lang");
    const message_list = {
      "ru": {
        loadingText: "Загрузка...",
        noResultsText: "Ничего не найдено",
        noChoicesText: "Нет вариантов выбора",
        itemSelectText: "Нажмите для выбора"
      },
      "en": {
        loadingText: "Loading...",
        noResultsText: "No results found",
        noChoicesText: "No choices to choose from",
        itemSelectText: "Press to select"
      }
    }
    return message_list[locale][key];
  }

  addValidateTrigger(html_element, choices_object) {
    let form, controller, fieldContainer;
    form = html_element.closest('form')
    if (form != undefined)
      controller = this.formController(form)
    if (controller != undefined)
      fieldContainer = html_element.closest('.form-group');

    if (controller != undefined && fieldContainer != undefined) {
      choices_object.passedElement.element.addEventListener(
        'change',
        function (event) {
          controller.checkChoicesMulti(fieldContainer);
        },
        false,
      );
    }
  }

  formController(form_element) {
    return this.application.getControllerForElementAndIdentifier(form_element, "layout--form")
  }

  thisController(form_element) {
    return this.application.getControllerForElementAndIdentifier(form_element, "deals--all-air-tickets")
  }

}