import BSN from "bootstrap.native";
import AutoNumeric from 'autonumeric';
import vNotify from "../vanilla-notify"
import { getMetaValue } from "helpers";
import { ApplicationController } from "../application_controller";
import Choices from "choices.js";

const TABLE_ID = "deal_air_tickets_form_table"

export default class extends ApplicationController {
  static targets = [
    "main_form",
    "is_guide_included",
    "total_count",
    "form_count",
    "modal_currency_field",
    "tourist_fields_template",
    "form_table_tbody",
    "form_table",
    "air_tickets_link",
    "guide_select",
    "air_price_select",
    "form_table",
    "form_table_tbody",
    "tickets_pack_search_filter"
  ]

  connect() {
    // form set total count
    if (this.hasMain_formTarget) {
      this.setCountHtml();
    }

    if (this.hasForm_tableTarget) this.setFormTotalHtml();
    if (this.hasAir_price_selectTarget) this.initAirPriceChoices();
  }

  toggleAirTicketsTable(event) {
    event.preventDefault();
    let element_url = event.currentTarget.getAttribute('href');
    let table = document.querySelector(element_url);
    event.currentTarget.classList.toggle("collapsed");
    if (table) table.classList.toggle("show");
  }

  showHideGuideSelectTag(event){
    const guide_select = document.getElementById("guide_id")
    let choices = guide_select.choices;

    choices.setChoiceByValue("")
    if (event.target.checked) {
      choices.enable();
    } else {
      choices.disable();
    }
    this.onChangeFilter();
  }

  async addGroupSelectedService(event) {
    event.preventDefault();
    const btn_target = event.currentTarget

    const url = btn_target.getAttribute("data-url")
    if (!btn_target.classList.contains("disabled")) {
      await this.addGroupServiceRequest(url)
    }
  }

  onChangeFilter() {
    const btn_target = document.getElementById("add_pack_tickets_button")
    let status = true
    this.tickets_pack_search_filterTargets.forEach((el) => {
      if (el.value == '' && !el.disabled){
        status = false
        return
      }
    })
    if (status)
      btn_target.classList.remove("disabled")
    else
      btn_target.classList.add("disabled")
  }

  async addGroupServiceRequest(url) {
    if (this.hasTickets_pack_search_filterTarget) {
      const data = new FormData();

      this.tickets_pack_search_filterTargets.forEach((el) => {
        data.append(el.id, el.value);
      })

      await fetch(url, {
        body: data,
        method: "POST",
        dataType: "text/javascript",
        credentials: "include",
        headers: {
          "X-CSRF-Token": getMetaValue("csrf-token")
        },
      }).then(function (response) {
        return response.text()
      }).then(text => {
        const table = document.getElementById(TABLE_ID)
        table.tBodies[0].insertAdjacentHTML("beforeend", text);
        this.reindexFormTableRows()
      })
    }
  }

  isGuideIncluded(event) {
    this.setCountHtml();
    this.enableDisableGuide();
  }

  openModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalXL(url);
  }

  collapseServices(event) {
    event.preventDefault();
    let target = event.currentTarget;
    let collapse = new BSN.Collapse(target)
    if (target.classList.contains("collapsed")) collapse.show();
    else collapse.hide();
  }

  // update deal service tr and sub table
  onPostPutSuccess(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      // show notify
      window.vNotify.success({ text: data.notification_message, title: data.notification_title });
      // show child if needed
      const rowId = data.deal_service_id
      // update table data
      // this.updateTable(rowId)
      let airTicketsBody = document.getElementById("deal_services_air_tickets")
      if (airTicketsBody) {
        airTicketsBody.innerHTML = data.table_content
      }
      if (data.save_and_new === true || data.save_and_new === "true") {
        // modal set content
        window.layout_modal_xl.setContent(data.form_content)
      } else {
        // hide form modal
        window.layout_modal_xl.hide();
      }
    }
  }

  // update deal service form
  onPostPutError(event) {
    const [data, status, xhr] = event.detail;
    // modal set content
    window.layout_modal_md.setContent(data.form_content)
  }

  onAirTicketsPostPutSuccess(event) {
    const [data, status, xhr] = event.detail;
    const deal_air_tickets_tab = document.getElementById("deal_services_air_tickets")
    let content_url = deal_air_tickets_tab.getAttribute("data-layout--load-content-url")

    if (deal_air_tickets_tab && data) {
      // show notify
      window.vNotify.success({ text: data.notification_message, title: data.notification_title });
      const rowId = data.deal_service_id
      this.loadAndSetContent(content_url, deal_air_tickets_tab, rowId)

      // hide form modal
      if (data.save_and_new === true || data.save_and_new === "true") {
        // modal set content
        window.layout_modal_xl.setContent(data.form_content)
      } else {
        window.layout_modal_xl.hide();
      }
    }
  }

  async loadAndSetContent(content_url, content_target, rowId) {
    let content_response = await fetch(content_url);
    let content = await content_response.text();
    content_target.innerHTML = content;
    const open_link = document.getElementById("service_air_tickets_child_" + rowId)
    this.openChildTable(open_link)
  }

  openChildTable(link_target) {
    // open child
    let element_url = link_target.getAttribute('href');
    let table = document.querySelector(element_url);
    link_target.classList.toggle("collapsed");
    if (table) table.classList.toggle("show");
  }


  commissionChange(event) {
    const tr = event.currentTarget.closest("tr");
    this.setTrTotalHtml(tr);
    this.setFormTotalHtml();
  }

  priceChange(event) {
    const tr = event.currentTarget.closest("tr");
    this.setTrTotalHtml(tr);
    this.setFormTotalHtml();
  }

  countChange(event) {
    const tr = event.currentTarget.closest("tr");
    this.setTrTotalHtml(tr);
    this.setFormTotalHtml();
  }

  removeFileds(event) {
    event.preventDefault();
    let response = confirm(event.currentTarget.getAttribute("data-confirm-message"));
    if (response) {
      let tr_id = event.currentTarget.closest("tr").getAttribute("data-id");
      let id = event.currentTarget.getAttribute("data-id")
      if (id) {
        if (id != "nil") {
          let destroy_target = document.getElementById(id)
          if (destroy_target) destroy_target.value = true
        }
      }
      document.querySelectorAll("tr[data-id='"+tr_id+"']").forEach( (tr) => {
        tr.remove();
      })
      this.reindexFormTableRows()
      this.setFormTotalHtml();
    }
  }

  //
  // private
  //

  //
  // FORM
  //
  setCountHtml() {
    let total_count_target = this.total_countTarget
    let text = total_count_target.getAttribute("data-text")
    total_count_target.innerHTML = text + " " + super.setNumericFormat(this.calculateTotalCount());
  }

  enableDisableGuide() {
    const guide_select_target = document.getElementById("deal_service_guide_id")
    const is_guide_target = this.is_guide_includedTarget
    let choices = guide_select_target.choices;

    choices.setChoiceByValue("")

    if (is_guide_target.checked) {
      guide_select_target.setAttribute("required", "required")
      choices.enable();
      // choices.showDropdown();
    } else {
      choices.disable();
      guide_select_target.removeAttribute("required")
    }


  }

  calculateTotalCount() {
    let tourists_count = parseInt(this.form_countTarget.value.replace(",", '.').replace(/[^\d.-]/g, ''))
    let is_guide_included = this.is_guide_includedTarget.checked == true
    if (is_guide_included) tourists_count += 1
    return tourists_count
  }
  //
  // END FORM
  //

  showApplicationsTable(div_target) {
    let tr = div_target.closest('.tabulator-row')
    let icon = div_target.querySelector('i')
    if (!tr.classList.contains("show-child-table")) tr.classList.toggle("show-child-table")
    if (icon) {
      if (icon.classList.contains("fa-angle-down")) {
        icon.classList.remove("fa-angle-down")
        icon.classList.add("fa-angle-up")
      } else {
        icon.classList.remove("fa-angle-up")
        icon.classList.add("fa-angle-down")
      }
    }
  }
  async addNewTicket(event) {
    event.preventDefault();
    const btn_target = event.currentTarget
    const url = btn_target.getAttribute("data-url")
    if (!btn_target.classList.contains("disabled")) {
      await this.addServiceRequest(url)
    }
  }

  async addServiceRequest(url) {
    let service_input_id = "deal_service_service_type_route_train_id"
    let service_target = document.getElementById(service_input_id)
    let supplier_target = document.getElementById("deal_service_supplier_id")
    if (service_target.value) {
      const data = new FormData();
      data.append("service_type_route_train_id", service_target.value);
      data.append("supplier_id", supplier_target.value);

      await fetch(url, {
        body: data,
        method: "POST",
        dataType: "text/javascript",
        credentials: "include",
        headers: {
          "X-CSRF-Token": getMetaValue("csrf-token")
        },
      }).then(function (response) {
        return response.text()
      }).then(text => {
        const table = document.getElementById(TABLE_ID)
        const rows_count = table.tBodies[0].querySelectorAll("tr:not(.hidden)").length + 1;
        let html = text.replace(/NEW_ROW_INDEX/g, rows_count)
        table.tBodies[0].insertAdjacentHTML("beforeend", html);
        this.reindexFormTableRows()
      })

    } else {
      alert('Выберите направление');
    }
  }



  //
  // reindex table
  //
  reindexFormTableRows() {
    const table = document.getElementById(TABLE_ID)
    const rows = table.tBodies[0].querySelectorAll("tr:not(.hidden)")
    for (let i = 0; i < rows.length; i++) {
      let tr = rows[i];
      const index_target = tr.querySelector("span.index")
      index_target.innerHTML = i + 1
    }
  }

  //
  // set tr sum amount
  //
  setTrTotalHtml(tr) {
    const totals_hash = this.calculateTrSum(tr);
    const total_main_value_html = tr.querySelector(".total_main_value")
    total_main_value_html.innerHTML = super.setNumericFormat(totals_hash["main"]);

    const total_additional_value_html = tr.querySelector(".total_additional_value")
    total_additional_value_html.innerHTML = super.setNumericFormat(totals_hash["additional"]);
  }

  //
  // calculate tr sum amount
  //
  calculateTrSum(tr) {
    const main_additional_rate = tr.getAttribute("data-main-additional-rate").replace(",", '.').replace(/[^\d.-]/g, '');
    const service_currency_to_additional_rate = tr.getAttribute("data-service-currency-to-additional-rate").replace(",", '.').replace(/[^\d.-]/g, '');
    const service_currency_to_main_rate = tr.getAttribute("data-service-currency-to-main-rate").replace(",", '.').replace(/[^\d.-]/g, '');

    // const currency_target = tr.querySelector("ul.nav.nav-radio.nav-radio--group input[type='radio']:checked")
    const price_target = tr.querySelector("input.price_input")
    const count_target = tr.querySelector("input.count_input")

    let price = parseFloat(price_target.value.replace(",", '.').replace(/[^\d.-]/g, ''))
    let count = parseFloat(count_target.value.replace(",", '.').replace(/[^\d.-]/g, ''))
    // let to_main_rate = parseFloat(currency_target.getAttribute("data-rate-to-main").replace(",", '.').replace(/[^\d.-]/g, ''))

    // const to_additional_rate = parseFloat(currency_target.getAttribute("data-rate-to-additional").replace(",", '.').replace(/[^\d.-]/g, ''))
    price = price || 0
    count = count || 0
    // to_main_rate = to_main_rate || 0
    // to_additional_rate = to_additional_rate || 0

    let main_total = price * count * service_currency_to_main_rate
    let additional_total = price * count * service_currency_to_additional_rate

    return {
      "main": main_total,
      "additional": additional_total
    }
  }

  initAirPriceChoices() {
    //
    // выбор билета, обновляем цену
    //
    let target = this.air_price_selectTarget
    // console.log(target);
    let form_target = target.closest('form')
    // console.log(form_target);
    let this_controller = this.thisController(form_target)

    // console.log(this_controller);
    let tr = target.closest("tr")
    let price_target = tr.querySelector(".price_input")

    const options = JSON.parse(target.dataset.options || null);
    const selected = JSON.parse(target.dataset.selected || null);
    let choice;

    if (target.choices) {
      choice = target.choices
    } else {
      choice = new Choices(target, {
        placeholder: false,
        removeItemButton: false,
        removeItems: false,
        searchEnabled: false,
        loadingText: this.messageText("loadingText"),
        noResultsText: this.messageText("noResultsText"),
        noChoicesText: this.messageText("noChoicesText"),
        itemSelectText: this.messageText("itemSelectText")
      })
      target.choices = choice;
    }
    if (options) choice.setChoices(options, "value", "label", true)
    if (selected) choice.setChoiceByValue(selected)
    // change
    choice.passedElement.element.addEventListener(
        "change",
        function (event) {
          let price = choice.getValue().customProperties.price;
          // console.log(price_target);
          // console.log(tr);
          price_target.numeric.set(price)
          this_controller.setTrTotalHtml(tr)
          this_controller.setFormTotalHtml()
        },
        false,
    );
    this.addValidateTrigger(target, choice)
  }

  //
  // итого для формы
  //
  setFormTotalHtml() {
    const train_tickets_form_total_main_target = document.getElementById("air_tickets_form_total_main_value")
    const train_tickets_form_total_additional_target = document.getElementById("air_tickets_form_total_additional_value")
    const totals_hash = this.calculateFormTotal();

    train_tickets_form_total_main_target.innerHTML = super.setNumericFormat(totals_hash["main"]);
    train_tickets_form_total_additional_target.innerHTML = super.setNumericFormat(totals_hash["additional"]);
  }


  //
  // расчет итого по строкам таблицы
  //
  calculateFormTotal() {
    const table = document.getElementById(TABLE_ID)
    const rows = table.tBodies[0].rows
    let totals_hash = {
      "main": 0,
      "additional": 0
    }
    for (let i = 0; i < rows.length; i++) {
      let tr = rows[i];
      let row_totals_hash = this.calculateTrSum(tr);
      totals_hash["main"] += row_totals_hash["main"]
      totals_hash["additional"] += row_totals_hash["additional"]
    }
    return totals_hash
  }

  messageText(key) {
    let locale = document.body.getAttribute("data-lang");
    const message_list = {
      "ru": {
        loadingText: "Загрузка...",
        noResultsText: "Ничего не найдено",
        noChoicesText: "Нет вариантов выбора",
        itemSelectText: "Нажмите для выбора"
      },
      "en": {
        loadingText: "Loading...",
        noResultsText: "No results found",
        noChoicesText: "No choices to choose from",
        itemSelectText: "Press to select"
      }
    }
    return message_list[locale][key];
  }

  addValidateTrigger(html_element, choices_object) {
    let form, controller, fieldContainer;
    form = html_element.closest('form')
    if (form != undefined)
      controller = this.formController(form)
    if (controller != undefined)
      fieldContainer = html_element.closest('.form-group');

    if (controller != undefined && fieldContainer != undefined) {
      choices_object.passedElement.element.addEventListener(
          'change',
          function (event) {
            controller.checkChoicesMulti(fieldContainer);
          },
          false,
      );
    }
  }

  formController(form_element) {
    return this.application.getControllerForElementAndIdentifier(form_element, "layout--form")
  }

  thisController(form_element) {
    return this.application.getControllerForElementAndIdentifier(form_element, "deals--airTickets")
  }

}